import clsx from 'clsx';
import { forwardRef, useRef } from 'react';
import { REPORT_RISK_TYPE_CLICK, trackEvent } from '../../utils/googleAnalytics';

// Creates an accessible tab section. See https://dev.to/eevajonnapanula/keyboard-accessible-tabs-with-react-5ch4
export const TabbedSection = forwardRef(
  ({ activeTabIndex, children, setActiveTabIndex, tabs, wrapperClass }, ref) => {
    const getTabClasses = active => {
      return clsx(
        'flex justify-between gap-2 sm:gap-8 relative z-10 cursor-pointer border border-rain px-4 py-4',
        active ? '!border-b-snow bg-snow' : 'bg-ice'
      );
    };

    const tabRefs = useRef({});

    const setActiveTabIndexAndTrack = index => {
      setActiveTabIndex(index);
      trackEvent(REPORT_RISK_TYPE_CLICK, 'Risk Profile Toggle', { index: index });
    };

    const setActiveTabIndexAndFocus = index => {
      setActiveTabIndex(index);
      trackEvent(REPORT_RISK_TYPE_CLICK, 'Risk Profile Toggle', { index: index });
      if (tabRefs.current && tabRefs.current[index]) {
        tabRefs.current[index].focus();
      }
    };

    return (
      <div
        className={wrapperClass || 'flex justify-center bg-fossil px-2 py-2 md:px-8 md:py-6'}
        ref={ref}
      >
        <div className="w-full max-w-screen-xl">
          <ul
            aria-label="List of Risk Tabs"
            className="flex"
            onKeyDown={event => {
              if (event.key === 'ArrowLeft') {
                const prev = activeTabIndex - 1;
                setActiveTabIndexAndFocus(Math.max(prev, 0));
              }
              if (event.key === 'ArrowRight') {
                const next = activeTabIndex + 1;
                setActiveTabIndexAndFocus(Math.min(next, tabs.length - 1));
              }
            }}
            role="tablist"
          >
            {tabs.map((tab, index) => {
              return (
                <li className="m-0" key={tab.label} role="presentation">
                  <button
                    aria-controls={`tabPanel_${index}`}
                    aria-selected={activeTabIndex === index}
                    id={`tab_${index}`}
                    className={getTabClasses(activeTabIndex === index)}
                    onClick={() => setActiveTabIndexAndTrack(index)}
                    ref={element => (tabRefs.current[index] = element)}
                    role="tab"
                    tabIndex={activeTabIndex === index ? 0 : -1}
                  >
                    <div>
                      <div className="mb-1 font-condensed text-lg font-bold">{tab.label}</div>
                      <div className="text-left text-sm italic text-rain">{tab.subLabel}</div>
                    </div>
                    <img alt={`${tab.label} icon`} className="block h-8 w-8" src={tab.icon} />
                  </button>
                </li>
              );
            })}
          </ul>
          <section
            aria-labelledby={`tab_${activeTabIndex}`}
            id={`tabPanel_${activeTabIndex}`}
            role="tabpanel"
          >
            <div className="-translate-y-[1px] border border-rain bg-snow pt-4">{children}</div>
          </section>
        </div>
      </div>
    );
  }
);
