import { useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { ADDRESS_LOOKUP, trackEvent } from '../../utils/googleAnalytics';
import { COVERAGE_AREA_BOUNDS } from '../../utils';

export const AddressSearchBar = ({
  onAddressClickCb = null, // Use to override default behavior on address click
  placeholder = 'Enter your address and assess your risk',
}) => {
  const navigate = useNavigate();

  const inputRef = useRef(null);

  useEffect(() => {
    const initPlaces = async () => {
      await window.google.maps.importLibrary('places');

      const options = {
        // bounds are biased toward coverage area
        bounds: COVERAGE_AREA_BOUNDS,
        componentRestrictions: { country: 'us' },
        fields: ['formatted_address', 'geometry.location'],
        strictBounds: false,
        types: ['address'],
      };

      // https://developers.google.com/maps/documentation/javascript/reference/places-widget#Autocomplete
      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, options);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();

        const { formatted_address, geometry } = place;

        // If a user just hits return in the search without selecting an autocomplete option,
        // we don't get any information (the component doesn't even hit the endpoint). Just
        // return in this case and force the user to select from the dropdown
        if (!formatted_address || !geometry) {
          return;
        }
        const { location } = geometry;
        const coordinates = [location.lng(), location.lat()];
        trackEvent(ADDRESS_LOOKUP, 'Address Search', { address: formatted_address, coordinates });

        // Method to override the address click default behavior
        if (onAddressClickCb) {
          onAddressClickCb({ address: formatted_address, coordinates });
          inputRef.current.value = '';
          return;
        }
        const encodedAddress = encodeURIComponent(formatted_address);
        navigate(`/property/overview?address=${encodedAddress}&lonlat=${coordinates}`);
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      });
    };

    initPlaces();
  }, [navigate, onAddressClickCb]);

  return <input ref={inputRef} placeholder={placeholder} />;
};
