import floodIcon from '../assets/icons/flood-slate.svg';
import fireIcon from '../assets/icons/fire-slate.svg';
import calculatorIcon from '../assets/icons/calculator.svg';
import lineGraphIcon from '../assets/icons/line-graph.svg';
import docIcon from '../assets/icons/doc.svg';
import mitigationIcon from '../assets/icons/mitigation.svg';

import idrtLogo from '../assets/idrt-logo.svg';
import tdemLogo from '../assets/tdem-logo.svg';
import texasAandMLogo from '../assets/texas-a-and-m-logo.svg';

const wrapperStyle =
  'flex max-w-screen-xl flex-wrap items-center justify-center gap-4 md:gap-8 px-4';
const twoItemWrapper = 'flex gap-4 md:gap-8';
const columnListStyle =
  'text-l flex shrink flex-col [&>li]:my-2 [&>li]:text-center [&>li]:text-rain lg:[&>li]:w-52 lg:[&>li]:text-xl';
const iconsStyle = 'flex justify-center';
const twoIconsStyle = iconsStyle + ' gap-2.5';

const iconWidth = '34px';

export const HowItWorks = () => {
  return (
    <>
      <div>
        <div className="flex items-center justify-center gap-6 bg-rain px-4 py-2 sm:gap-8">
          <a href="https://idrt.tamug.edu/">
            <img src={idrtLogo} alt="IDRT Logo" className="h-[33px] sm:h-[56px]" />
          </a>
          <a href="https://www.tdem.texas.gov/">
            <img src={tdemLogo} alt="TDEM Logo" className="h-[29px] sm:h-[48px]" />
          </a>
          <a href="https://www.tamu.edu/">
            <img src={texasAandMLogo} alt="Texas A&M Logo" className="h-[29px] sm:h-[46px]" />
          </a>
        </div>
        <div className="p-4 shadow sm:p-6">
          <div className="flex justify-center">
            <p className="text-md m-0 max-w-screen-xl px-4 pb-4 sm:px-6 sm:pb-6 md:text-lg">
              Buyers Aware is an analytical tool to assess the flood and wildfire risk to individual
              residential structures in Hurricane Harvey-impacted areas in Texas. Created for free
              public use by the Institute for a Disaster Resilient Texas at Texas A&M University
              with support from the Texas Division of Emergency Management.
            </p>
          </div>
          <div className="flex justify-center">
            <ul className={wrapperStyle}>
              <div className={twoItemWrapper}>
                <div className={columnListStyle}>
                  <li className={twoIconsStyle}>
                    <img src={floodIcon} alt="Rain drop icon" width={iconWidth} />
                    <img src={fireIcon} alt="Wildfire icon" width={iconWidth} />
                  </li>
                  <li>
                    <b>See</b> your property’s hazard risk
                  </li>
                </div>

                <div className={columnListStyle}>
                  <li className={twoIconsStyle}>
                    <img src={calculatorIcon} alt="Calculator icon" width={iconWidth} />
                    <img src={lineGraphIcon} alt="Line Graph icon" width={iconWidth} />
                  </li>
                  <li>
                    <b>Learn</b> how your risk is calculated
                  </li>
                </div>
              </div>

              <div className={twoItemWrapper}>
                <div className={columnListStyle}>
                  <li className={iconsStyle}>
                    <img src={docIcon} alt="Document icon" width={iconWidth} />
                  </li>
                  <li>
                    <b>Download</b> a detailed report
                  </li>
                </div>

                <div className={columnListStyle}>
                  <li className={iconsStyle}>
                    <img src={mitigationIcon} alt="House with shield icon" width={iconWidth} />
                  </li>
                  <li>
                    <b>Explore</b> mitigation strategies
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
