import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import ScrollToTop from './components/general/ScrollToTop';
import { Main } from './components/Main';
import Report from './components/Report';
import store from './store';
import 'react-toastify/dist/ReactToastify.css';

// Function to load Google Analytics script dynamically
const loadGAScript = (trackingId) => {
  if (!document.querySelector(`script[src="https://www.googletagmanager.com/gtag/js?id=${trackingId}"]`)) {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      window.gtag = gtag;
      gtag('js', new Date());
    };
  }
};

// Custom hook to handle Google Analytics pageview tracking
const usePageViews = () => {
  const location = useLocation();

  useEffect(() => {
    const domain = window.location.hostname;
    const googleAnalyticsToken =
      domain === process.env.REACT_APP_Google_Analytics_Org_Url
        ? process.env.REACT_APP_Google_Analytics_Token_Org
        : domain === process.env.REACT_APP_Google_Analytics_Com_Url
        ? process.env.REACT_APP_Google_Analytics_Token_Com
        : null;

    if (googleAnalyticsToken) {
      // Load Google Analytics script if not already loaded
      loadGAScript(googleAnalyticsToken);

      if (window.gtag) {
        window.gtag('config', googleAnalyticsToken, {
          page_path: location.pathname,
        });
      }
    }
  }, [location]);
};

// Router component
const Router = () => {
  usePageViews(); // Call the custom hook to track page views

  return (
    <>
      <Routes>
        <Route path="/*" element={<Main />} />
        <Route path="/report" element={<Report />} />
      </Routes>
      <ScrollToTop />
      <Tooltip className="z-50 !bg-rain !shadow" id="app-tooltip" />
    </>
  );
};

// App component
const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  </Provider>
);

export default App;
