import { SearchSection } from './SearchSection';
import { HowItWorks } from './HowItWorks';
import { CallToActions } from './CallToActions';
import { trackEvent, LANDING_PAGE_SCROLL_PAST_ADDRESS_LOOKUP } from '../utils/googleAnalytics';
import { useState } from 'react';

export const Landing = () => {
  const [alertScrollPastSearchBar, setAlertScrollPastSearchBar] = useState(false);

  window.onscroll = () => {
    if (document.documentElement.scrollTop > 575) {
      // Trigger the scroll alert once only
      if (!alertScrollPastSearchBar) {
        trackEvent(LANDING_PAGE_SCROLL_PAST_ADDRESS_LOOKUP, 'Page Navigation');
        setAlertScrollPastSearchBar(true);
      }
    }
  };

  return (
    <>
      <SearchSection />
      <HowItWorks />
      <CallToActions />
    </>
  );
};
