export const trackEvent = (action, category, params = {}) => {
  if (!window.gtag) {
    console.warn('Google Analytics is not initialized.');
    return;
  }

  window.gtag('event', action, {
    event_category: category,
    ...params,
  });
};

/* Variables to keep track of analytics categories and common events so they're kept consistent */

// CATEGORIES
export const NAVIGATION = 'navigation';
export const ADDRESS_LOOKUP = 'address_lookup';
export const LANDING_PAGE_SCROLL_PAST_ADDRESS_LOOKUP = 'landing_page_scroll_past_address_lookup';
export const GENERATE_REPORT = 'generate_report';
export const FULL_REPORT_CLICK = 'full_report_click';
export const MAP_PARCEL_CLICK = 'map_parcel_click';
export const MAP_RISK_TYPE_CLICK = 'map_risk_type_click';
export const MAP_ZOOM_CHANGED = 'map_zoom_changed';
export const MAP_ZOOM_NO_DATA_VISIBLE = 'map_zoom_no_data_visible';
export const MAP_OUTSIDE_COVERAGE_AREA = 'map_outside_coverage_area';
export const MAP_NEW_CENTER_COORDINATES = 'map_new_center_coordinates';
export const REPORT_RISK_TYPE_CLICK = 'report_risk_type_click';
