import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Flip, ToastContainer } from 'react-toastify';

import { NAVIGATION, trackEvent } from '../utils/googleAnalytics';
import { AreaMap } from './AreaMap';
import { Header } from './Header';
import { Landing } from './Landing';
import { About } from './About';
import { Methodology } from './Methodology';
import { Mitigation } from './Mitigation';
import { Results } from './Results';
import { Footer } from './Footer';
import { Terms } from './Terms';

export const Main = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (['/map', '/map/flood', '/map/fire'].includes(pathname)) {
      // We'll track map loads within the map component, so we
      // only have a single event per risk type
      return;
    }
    trackEvent(NAVIGATION, 'Page Navigation', { route_name: pathname });
  }, [pathname]);

  return (
    <div className="w-full">
      <ToastContainer autoClose={3000} position="bottom-right" transition={Flip} hideProgressBar />
      <Header />
      <main>
        <Routes>
          <Route path="/" exact={true} element={<Landing />} />
          <Route path="/about" element={<About />} />
          <Route path="/methodology" element={<Methodology />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/mitigation" element={<Mitigation />} />
          <Route path="/property/overview" element={<Results />} />
          <Route path="/map" element={<AreaMap />} />
          <Route path="/map/:riskType" element={<AreaMap />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};
