import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { formatDecimal2SigFig } from '../utils';

import { FIRE, FLOOD, MITIGATION_COPY, RISK_VARIABLE_COPY } from '../constants/general';
import {
  capitalize,
  formatDollarsRound,
  formatPercentRound,
  riskToText,
  riskToTextColorClass,
} from '../utils';
import FemaZoneWarning from './FemaZoneWarning';
import ResultsAssessment from './ResultsAssessment';
import RiskIcon from './general/RiskIcon';
import { FemaSpecialFloodAreaTooltip } from './general/InfoTooltips';
import CollapsableInfo from './general/CollapsableInfo';
import NeighborhoodRisk from './NeighborhoodRisk';

const italicSubHeaderClass = 'font-condensed italic text-rain mb-6';

/**
 * @param {{className: string | undefined, label: string}} props
 */
const TabbedSubheader = ({ className = '', label }) => (
  <h2 className={clsx('font-condensed font-bold', className)}>{label}</h2>
);

export const LineBreak = () => <div className="mx-6 h-0.5 bg-gray-200" />;

export const RiskProfile = ({ address, coordinates, data, label, riskType }) => {
  return (
    <div className="bg-gray-100 px-6 pb-8 pt-4 md:px-8 md:pt-6">
      <TabbedSubheader label={`${label} Profile`} />
      <div className="md:flex-no-wrap mt-8 flex flex-wrap justify-center gap-16">
        <div className="w-[200px] max-w-[200px]">
          <RiskIcon risk={data.risk} riskType={riskType} />
          {riskType === FLOOD && (
            <div className="mt-6">
              <FemaZoneWarning isFemaSpecialHazardArea={data.isFemaSpecialHazardArea} />
            </div>
          )}
        </div>
        <div className="w-full text-rain md:w-3/5">
          <ResultsAssessment
            address={address}
            coordinates={coordinates}
            data={data}
            riskType={riskType}
          />
        </div>
      </div>
    </div>
  );
};

export const RiskVariables = ({ riskType }) => {
  const risks = RISK_VARIABLE_COPY[riskType];
  const riskItemClass = risks.length > 1 ? 'md:w-[calc(33%-24px)]' : '';
  return (
    <div className="px-6 py-4 md:px-8 md:py-6">
      <TabbedSubheader
        label={
          riskType === FLOOD
            ? `Factors that contribute to ${capitalize(riskType)} Risk`
            : `Wildfire Risk`
        }
      />
      {riskType === FLOOD && (
        <h3 className={italicSubHeaderClass}>
          {`Our advanced statistical models include the following variables to assess the ${riskType} risk for
        this property`}
        </h3>
      )}
      <div className="mt-4 flex flex-wrap gap-6">
        {riskType === FLOOD &&
          risks.map(risk => (
            <div className={riskItemClass} key={risk.header}>
              <h4 className="font-condensed font-bold text-rain">{risk.header}</h4>
              <h5
                className={clsx(
                  italicSubHeaderClass,
                  'mb-2',
                  riskType === FLOOD && ' min-h-[24px]'
                )}
              >
                {risk.subHeader}
              </h5>
              <p className="mt-2 text-sm text-gray-700">{risk.main}</p>
            </div>
          ))}
        {riskType === FIRE && (
          <p className="mt-0 text-gray-700">
            Wildfire risk is determined using a combination of landscape characteristics, weather
            conditions, and wildfire management practices. The result is a measure of the likelihood
            of a wildfire burning at any given location. This method was developed and is maintained
            by the US Forest Service (
            <a className="underline" href="https://doi.org/10.2737/RDS-2016-0034-3">
              https://doi.org/10.2737/RDS-2016-0034-3
            </a>
            )
          </p>
        )}
      </div>
    </div>
  );
};

const PreviousClaims = ({ data, riskType }) => {
  if (riskType !== FLOOD) {
    const {
      areaChange: { fireHomesLost, fireAcres },
    } = data;
    return (
      <>
        <TabbedSubheader label="Previous damage" />
        <div className="mt-2">
          <p className="my-2 text-gray-700">
            This property is located in an area that has experienced a total of{' '}
            {formatDecimal2SigFig(fireAcres) || 0} acres burned since 2000. In this area
            approximately {fireHomesLost || 0} {fireHomesLost === 1 ? 'home' : 'homes'} were burned
            during a wildfire event since 2005.
          </p>
        </div>
      </>
    );
  }
  const { damageClaimAmount, damageClaimCount } = data;
  const damageClaimDollars = formatDollarsRound(damageClaimAmount);
  return (
    <>
      <TabbedSubheader label="Previous damage claims" />
      <div className="mt-2">
        <h5 className="my-2 font-bold text-gray-700">
          {damageClaimDollars || 0} of flood insurance claims have been paid in this area in the
          last 10 years.
        </h5>
        <p className="my-2 text-gray-700">
          {damageClaimDollars || 0} of flood damage has been paid for under the National Flood
          Insurance Program in this neighborhood since 2010. In this census tract, there have been{' '}
          {damageClaimCount || 0} flood damage claims filed with the National Flood Insurance
          Program in the last 10 years.*{' '}
        </p>
        <p className="text-xs">
          *Data on the number and total amount of claims paid represent only those properties that
          had active policies insured by the National Flood Insurance Program. These data may
          significantly understate the actual flood damage that occurred in this area due to several
          factors including the following: damaged properties may not have been insured at the time
          of the flood, damaged properties that were insured may have had damage that exceeded the
          policy limits of the insurance policy, or other damage may have occurred as a result of a
          hazard event that was not covered under a flood insurance policy. As such, consider
          previous damage claims as an underestimate of the true damages experienced.
        </p>
      </div>
    </>
  );
};

const InsuranceCoverage = ({ data, riskType }) => {
  if (riskType !== FLOOD) {
    return;
  }
  const { policyCount } = data;
  return (
    <>
      <TabbedSubheader label="Flood insurance coverage" />
      <div className="mt-2">
        <h5 className="my-2 font-bold text-gray-700">
          There are {policyCount || 0} active National Flood Insurance Program policies in your
          area.
        </h5>
        <p className="my-2 text-gray-700">
          Traditional homeowners' insurance does not cover damage from floods. The National Flood
          Insurance Program (NFIP) is the primary insurer of flood insurance in the U.S. but
          available through common insurance companies.
        </p>
      </div>
    </>
  );
};

const AreaChange = ({ data, riskType }) => {
  if (riskType !== FLOOD) {
    return (
      <>
        <TabbedSubheader className="mt-6" label="Future changes" />
        <h5 className="my-2 font-bold text-gray-700">Change in the Wildland Urban Interface</h5>
        <p className="mt-2 text-gray-700">
          The wildland urban interface is the area where homes and other structures meet wildland,
          vegetated areas. Homes here are closer to landcover and vegetation with higher fuel
          potential for wildfires. As development expands more homes will be built along the edges
          of wildlands leading to more potential for damage from large wildfires.
        </p>
      </>
    );
  }

  const {
    areaChange: { deltaImperv, deltaRain },
  } = data;

  const rainfallPctString = `${formatPercentRound(Math.abs(deltaRain) / 100)} ${
    deltaRain > 0 ? 'increase' : 'decrease'
  }`;

  const impervPctString = `${formatPercentRound(Math.abs(deltaImperv) / 100)} ${
    deltaImperv > 0 ? 'increase' : 'decrease'
  }`;

  return (
    <>
      <TabbedSubheader className="mt-6" label="Future changes" />
      <p className="mt-2 text-gray-700">
        Flood risk changes over time, driven by factors like changing weather and development
        patterns. This area has experienced a <span className="font-bold">{rainfallPctString}</span>{' '}
        in rainfall and a <span className="font-bold">{impervPctString}</span> in impervious
        surface. These trends could lead to an increased likelihood of experiencing flooding and
        incurring flood damage in the future.
      </p>
    </>
  );
};

export const AreaContext = ({ address, coordinates, data, riskType, reportPage = false }) => {
  const { areaRisk } = data;
  return (
    <div className="flex flex-wrap gap-6 px-6 py-4 md:flex-nowrap md:px-8 md:py-6">
      <div
        className="gap-6 md:w-[calc(33%-24px)]"
        // Don't break the map across multiple pages in downloaded report
        style={{ pageBreakInside: 'avoid' }}
      >
        <TabbedSubheader label="Surrounding Area" />
        <div className="mt-2 text-gray-700">
          Most properties in your area are{' '}
          <b>
            <span className={riskToTextColorClass(areaRisk)}>{riskToText(areaRisk)}</span> risk
          </b>
        </div>
        <NeighborhoodRisk coordinates={coordinates} riskType={riskType} />
        {!reportPage && (
          <Link
            className="mt-2 block font-condensed text-base font-bold text-rain underline"
            state={{ address, coordinates }}
            to={`/map/${riskType}`}
          >
            View {riskType} risk map of area
          </Link>
        )}
      </div>
      <div className="md:w-[calc(66%)]">
        <PreviousClaims data={data} riskType={riskType} />
        <InsuranceCoverage data={data} riskType={riskType} />
        <AreaChange data={data} riskType={riskType} />
      </div>
    </div>
  );
};

const InsuranceInfo = ({ data, riskType }) => {
  if (riskType !== FLOOD) return null;

  const { isFemaSpecialHazardArea } = data;

  const specialHazardInfo = isFemaSpecialHazardArea ? (
    <>
      This property is in the FEMA Special Flood Hazard Area (SFHA)
      <FemaSpecialFloodAreaTooltip className="inline-block -translate-y-1" />. Properties in the
      SFHA <strong>require flood insurance</strong> if you have a federally-backed mortgage.
    </>
  ) : (
    <>
      Since this property is not in the Special Flood Hazard Area (SFHA){' '}
      <FemaSpecialFloodAreaTooltip className="inline-block -translate-y-1" />, flood insurance is
      not required but it is still recommended.
    </>
  );

  return (
    <div className="mb-8 mt-2">
      <TabbedSubheader label="Flood Insurance" />
      <div className="mt-2 text-gray-700">
        {specialHazardInfo} Flood insurance is not included as part of homeowners insurance or
        renters insurance and <i>must be purchased as a separate policy.</i> For more information on
        insurance, contact a local insurance agent or obtain more information from{' '}
        <a className="underline" href="https://www.floodsmart.gov/">
          https://www.floodsmart.gov/
        </a>
        .
      </div>
    </div>
  );
};

const MitigationRecommendation = ({ data, riskType }) => {
  const capitalizedRisk = capitalize(riskType);
  const recommendMitigation = data.risk > 2;
  const recommendationType = data.risk > 3 ? <b>highly recommend</b> : 'recommend';

  const moreInfo =
    riskType === FLOOD ? (
      <>
        For more information on flood mitigation options, customized to your home type, please
        visit:{' '}
        <a className="underline" href="https://www.reducefloodrisk.org">
          https://www.reducefloodrisk.org
        </a>
        .
      </>
    ) : null;

  if (recommendMitigation) {
    return (
      <>
        {capitalizedRisk}s are damaging and costly events for residents. There are, however, several
        steps that can be taken to reduce your risk - these steps are referred to as mitigation.
        Based on your property’s risk level, we {recommendationType} reviewing the following details
        on insurance and {riskType} mitigation options. {moreInfo}
      </>
    );
  }
  return (
    <>
      {capitalizedRisk}s are damaging and costly events for residents. Even though your property has
      a low risk score, it's still essential to take precautions and prepare for any potential{' '}
      {riskType} risks. {moreInfo}
    </>
  );
};

const MitigationMoreInfo = ({ riskType }) => {
  if (riskType === FLOOD) {
    return (
      <>
        For more information on flood mitigation options, customized to your home type, please visit{' '}
        <a className="underline" href="https://www.reducefloodrisk.org/">
          https://www.reducefloodrisk.org/.
        </a>
      </>
    );
  }
  return (
    <>
      For more information on mitigating wildfire risk, see{' '}
      <a
        className="underline"
        href="https://www.nfpa.org/Public-Education/Fire-causes-and-risks/Wildfire/Preparing-homes-for-wildfire"
      >
        https://www.nfpa.org/Public-Education/Fire-causes-and-risks/Wildfire/Preparing-homes-for-wildfire
      </a>
      .
    </>
  );
};

export const MitigationTactics = ({ data, riskType }) => {
  const capitalizedRisk = capitalize(riskType);

  return (
    <div className="px-6 py-4 md:px-8 md:py-6 lg:w-2/3">
      <TabbedSubheader label={`${capitalizedRisk} Mitigation`} />
      <h6 className={italicSubHeaderClass}>Strategies to reduce your property’s {riskType} risk</h6>
      <p className="mb-8 mt-2 text-gray-700">
        <MitigationRecommendation data={data} riskType={riskType} />
      </p>
      <InsuranceInfo data={data} riskType={riskType} />
      <TabbedSubheader
        label={`${capitalizedRisk} Mitigation Strategies and Preparedness Tactics`}
      />
      <CollapsableInfo className="mb-6 mt-4" sections={MITIGATION_COPY[riskType]} />
      <MitigationMoreInfo riskType={riskType} />
    </div>
  );
};
