import { useState } from 'react';
import { downloadReport } from '../../services/api';
import { LinkLikeButton } from './Button';
import { ButtonLoader } from '../Loaders';
import { GENERATE_REPORT, trackEvent } from '../../utils/googleAnalytics';

export const DownloadReportLink = ({ address, children, coordinates }) => {
  const [reportLoading, setReportLoading] = useState(false);
  if (reportLoading) {
    return (
      <div className="opacity-50">
        <LinkLikeButton className="inline-block no-underline">Creating report...</LinkLikeButton>
        <div className="inline-block h-4 w-10">
          <div className="relative h-full">
            <ButtonLoader color="rain" />
          </div>
        </div>
      </div>
    );
  }
  return (
    <LinkLikeButton
      onClick={async () => {
        trackEvent(GENERATE_REPORT, 'Report Generation', { address, coordinates });
        setReportLoading(true);
        await downloadReport({ address, coordinates });
        setReportLoading(false);
      }}
    >
      {children}
    </LinkLikeButton>
  );
};
